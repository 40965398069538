<template>
  <section>
    <div class="content-header">
      <h2>Documents</h2>
    </div>
    <div class="content-wrapper">
      <ul>
        <li><em><a href="https://psc.gpei.ca/files/resiliency.pdf" target="_blank">How do I know how resilient I am?</a></em></li>
        <li><em><a :href="`${publicPath}docs/career/Roadmap-to-career-readiness - supporting students with disabilities (1).pdf`" download>Roadmap to Career Readiness: Supporting students with disabilities</a></em> (used with permission from Tracey Lloyd)</li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '08',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
